<template>
  <div v-if="threshold" class="header__cart__threshold">
    <div class="header__cart__threshold__percent" :style="`width: ${percentDone}%;`"></div>
    <span class="h6">
      <template v-if="!isQualified">
        You are <span>${{ this.amountAway }} {{ currency }}</span> away from <span>free shipping</span>
      </template>
      <template v-else>
        You have qualified for <span>free shipping</span>!
      </template>
    </span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'CartFreeShippingThreshold',

    props: {
      cartTotal: {
        type: Number,
        default: 0
      },
      threshold: {
        type: Number,
        default: 0
      }
    },

    computed: {
      ...mapGetters(['currency']),
      amountAway() {
        return (this.threshold - this.cartTotal).toFixed(2);
      },
      isQualified() {
        return this.amountAway <= 0;
      },
      percentDone() {
        if(!this.isQualified) {
          return (100 - ((this.amountAway / this.threshold) * 100));
        }

        return 100;
      }
    }
  }
</script>
