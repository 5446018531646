<template>
  <div v-if="item" class="header__product cart-item product">
    <div class="row">
      <div class="column small-3">
        <ShopifyLazyImage :to="link" :image="item.image" :width="100" />
      </div>
      <div class="column small-9">
        <div>
          <BrandLink
            v-if="(item.brand && item.brand._meta) || item.isBundle"
            :brand="item.brand"
            :isBundle="item.isBundle" />
          <span v-else class="brand">Beauty Marks</span>
          <nuxt-link :to="link" class="title">
            {{ item.title }}
          </nuxt-link>
          <ol v-if="isBundle && item.products && item.products.length">
            <li
              v-for="(bundleItem, index) in item.products"
              :key="bundleItem._meta && bundleItem._meta.uid">
              {{ index + 1 }}.
              <BrandLink
                v-if="bundleItem.brand && bundleItem.brand._meta"
                :brand="bundleItem.brand" />
              -
              <nuxt-link
                :to="`/shop/product/${
                  bundleItem._meta && bundleItem._meta.uid
                }`"
                class="title">
                {{ bundleItem.title }}
              </nuxt-link>
              <span v-if="bundleItem.variantTitle" class="size">
                , {{ bundleItem.variantTitle }}
              </span>
            </li>
          </ol>
          <template v-else>
            <span v-if="item.variantTitle" class="size">
              {{ item.variantTitle }}
            </span>
            <!--
            <AutoWidthSelect
              v-if="item.subscriptionFrequency"
              :id="'frequency-' + index"
              label="Every"
              :options="getFrequencyOptions()"
              v-model="item.subscriptionFrequency" />
              -->
          </template>
        </div>

        <div>
          <Quantity :index="index" :product="item" v-model="item.quantity" />

          <Price
            :price="item.isReward ? '0.00' : item.price"
            :salePrice="item.salePrice"
            class="price" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getFrequencyOptions } from '@/helpers'

  import ShopifyLazyImage from '@/components/shop/ShopifyLazyImage'
  import BrandLink from '@/components/shop/BrandLink'
  import AutoWidthSelect from '@/components/global/AutoWidthSelect'
  import Quantity from '@/components/shop/Quantity'
  import Price from '@/components/shop/Price'

  export default {
    name: 'CartItem',

    components: {
      ShopifyLazyImage,
      BrandLink,
      AutoWidthSelect,
      Quantity,
      Price,
    },

    props: {
      item: {
        type: Object,
        default: null,
      },
      index: {
        type: Number,
        default: 1,
      },
    },

    computed: {
      link() {
        return `/shop/product/${this.item._meta && this.item._meta.uid}`
      },
      isBundle() {
        return this.item.isBundle
      },
    },

    methods: {
      getFrequencyOptions,
    },
  }
</script>
