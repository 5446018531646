<template>
  <div class="header__product cart-item cart-item--sample product">
    <div class="cart-item__inner">
      <div class="row">
        <div class="column small-3">
          <PrismicLazyImage :image="image" :width="100" />
        </div>
        <div class="column small-9">
          <div>
            <span class="brand">Your Purchase Includes:</span>
            <span class="title uppercase">{{ text }}</span>
            <span>{{ description }}</span>
          </div>
          <span class="price uppercase">Free</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';

  export default {
    name: 'CartSample',

    components: {
      PrismicLazyImage
    },

    props: {
      image: {
        type: Object,
        default: null
      },
      text: {
        type: String,
        default: null
      },
      description: {
        type: String,
        default: null
      }
    }
  }
</script>
