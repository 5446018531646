<template>
  <div v-if="numInStockProducts > 0" class="header__cart__carousel">
    <h3 class="label">{{ heading }}</h3>
    <VueSlickCarousel v-show="numInStockProducts > 0" v-bind="sliderSettings" class="header__cart__carousel__slider" :key="numInStockProducts">
      <template #prevArrow="arrowOption">
        <button class="button">
          <SvgIcon name="chevron-left-light" title="Previous" />
        </button>
      </template>
      <ProductCard
        v-for="product in inStockProducts"
        :key="product.variantId"
        :product="product"
        :isSmall="true"
      />
      <template #nextArrow="arrowOption">
        <button class="button">
          <SvgIcon name="chevron-right-light" title="Next" />
        </button>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import { fetchProductByHandle } from '@/shopify/products'

  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';

  import SvgIcon from '@/components/global/SvgIcon';
  import ProductCard from '@/components/shop/ProductCard';

  export default {
    name: 'CartCarousel',

    components: {
      VueSlickCarousel,
      SvgIcon,
      ProductCard
    },

    props: {
      heading: {
        type: String,
        default: null
      },
      products: {
        type: Array,
        default: null
      }
    },

    data() {
      return {
        inStockProducts: [],
        sliderSettings: {
          fade: false,
          infinite: false,
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      }
    },

    computed: {
      ...mapState({
        locale: state => state.locale
      }),
      numInStockProducts() {
        return this.inStockProducts ? this.inStockProducts.length : 0;
      }
    },

    methods: {
      async checkIfProductInStock(shopifyProduct) {
        if (shopifyProduct) {
          try {
            const { data } = await this.$shopify.client.query({
              query: fetchProductByHandle,
              variables: { handle: shopifyProduct.handle },
              fetchPolicy: 'no-cache',
            })

            const { productByHandle } = data

            if (!productByHandle) return false

            return productByHandle.availableForSale;
          } catch (err) {
            return false;
          }
        }
      }
    },

    async mounted() {
      this.products.forEach(({product}) => {
        const shopifyProduct = this.locale == 'en-ca' ? product.shopify_product_ca : product.shopify_product_us;

        this.checkIfProductInStock(shopifyProduct).then(result => {
          if (result) {
            this.inStockProducts.push(product);
          }
        });
      });
    }
  }
</script>
