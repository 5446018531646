<template>
  <Expand
    type="cart"
    transition="slide-left"
    :class="[
      { 'is-empty': isCartEmpty },
      { 'has-gift-wrap': hasGiftWrap },
      { 'has-discount-note': showDiscountNote },
    ]">
    <template v-slot:top>
      <h2>Your Cart ({{ numCartItems }})</h2>
      <span v-if="!isCartEmpty && showDiscountNote" class="h2">
        Discount shown on checkout page
      </span>
    </template>

    <template v-slot:content>
      <template v-if="isCartEmpty">
        <div>
          <p class="large">Your cart is empty</p>

          <template v-if="hasMenu">
            <h3 class="label">Suggested</h3>

            <PrismicMenu :menu="menu" class="split" />
          </template>

          <CartCarousel
            v-if="showCarousel"
            :heading="carousel.heading"
            :products="carousel.products"
          />

          <CartValueProps :valueProps="valueProps" />
        </div>

        <IconCallout :type="callout" />
      </template>

      <template v-else>
        <CartFreeShippingThreshold
          :cartTotal="parseFloat(cartTotal)"
          :threshold="freeShippingThreshold"
        />

        <CartItem
          v-for="item in nonGiftWrapItems"
          :key="item.variantId"
          :item="item" />

        <CartSample
          v-if="showSamples"
          :image="samples.image"
          :text="samples.text"
          :description="samples.description"
        />

        <div class="header__cart__more">
          <div class="header__cart__input">
            <label for="cart-note" class="accessible">Notes</label>
            <textarea
              id="cart-note"
              v-model="cartNote"
              placeholder="Add notes"></textarea>
          </div>

          <div class="header__cart__points">
            <div class="header__cart__points__left">
              <strong>{{ potentialSmilePoints }}</strong>
            </div>
            <div class="header__cart__points__right">
              <p>
                {{ isLoggedIn ? 'Earn' : 'You could earn' }}
                <strong>{{ potentialSmilePoints }} Points</strong>
                with this purchase.
              </p>
              <ArrowLink
                v-if="isLoggedIn"
                to="/account/beauty-marks"
                text="View My Rewards" />
              <ArrowLink
                v-else
                @click="openHeaderMenuExpand('register')"
                text="Sign Up" />
            </div>
          </div>

          <CartCarousel
            v-if="showCarousel"
            :heading="carousel.heading"
            :products="carousel.products"
          />
        </div>

        <CartValueProps :valueProps="valueProps" />
      </template>
    </template>

    <template v-slot:bottom>
      <template v-if="!isCartEmpty">
        <div class="header__cart__subtotal">
          <span class="label">Subtotal</span>
          <span class="subtotal">${{ cartTotal }}</span>
        </div>

        <Button
          :text="isLoading ? 'Loading' : 'Checkout (<span class=\'secure\'>Secure</span>)'"
          :class="{ loading: isLoading }"
          :hideArrow="isLoading"
          @click="goToCheckout" />

        <div v-if="hasGiftWrap" class="header__cart__gift-wrap">
          <input
            id="gift_wrap"
            type="checkbox"
            name="gift_wrap"
            v-model="giftWrap"
            value="true"
            class="radio" />
          <label for="gift_wrap">
            Gift Wrap It? (${{ giftWrapProduct.variants[0].price }})
          </label>
        </div>
      </template>
    </template>
  </Expand>
</template>

<script>
  import axios from 'axios'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import { updateCheckoutAttributes } from '@/shopify/checkout'

  import { getTitle } from '@/helpers'

  import Expand from '@/components/header/Expand'
  import PrismicMenu from '@/components/prismic/PrismicMenu'
  import CartFreeShippingThreshold from '@/components/header/items/CartFreeShippingThreshold'
  import CartItem from '@/components/header/items/CartItem'
  import CartSample from '@/components/header/items/CartSample'
  import CartCarousel from '@/components/header/items/CartCarousel'
  import CartValueProps from '@/components/header/items/CartValueProps'
  import IconCallout from '@/components/global/IconCallout'
  import Button from '@/components/global/Button'
  import SvgIcon from '@/components/global/SvgIcon'
  import ArrowLink from '@/components/global/ArrowLink'

  export default {
    name: 'Cart',

    components: {
      Expand,
      PrismicMenu,
      CartFreeShippingThreshold,
      CartItem,
      CartSample,
      CartCarousel,
      CartValueProps,
      IconCallout,
      Button,
      SvgIcon,
      ArrowLink,
    },

    data() {
      return {
        cartNote: '',
        giftWrap: false,
        isLoading: false,
        buttonText: 'Checkout',
        potentialSmilePoints: 0,
      }
    },

    computed: {
      ...mapState({
        menu: state => state.cart.menu,
        user: state => state.user?.data,
        locale: state => state.locale,
        callout: state => state.cart.callout,
        cartItems: state => state.cart.items,
        checkoutId: state => state.cart.checkout.id,
        showDiscountNote: state => state.cart.showDiscountNote,
        freeShippingThreshold: state => state.cart.freeShippingThreshold,
        showSamples: state => state.cart.showSamples,
        samples: state => state.cart.samples,
        showCarousel: state => state.cart.showCarousel,
        carousel: state => state.cart.carousel,
        enableGiftWrap: state => state.cart.enableGiftWrap,
        giftWrapProduct: state => state.cart.giftWrapProduct,
        enableTieredDiscount: state => state.cart.enableTieredDiscount,
        valueProps: state => state.cart.valueProps,
        smilePoints: state => state.smile?.points_balance
      }),

      ...mapGetters([
        'nonGiftWrapItems',
        'numCartItems',
        'cartTotal',
        'isLoggedIn',
        'cartHasSubscription',
      ]),

      isCartEmpty() {
        return this.numCartItems == 0
      },

      hasMenu() {
        if (!this.menu) return false

        if (!this.menu.links) return false

        return this.menu.links.length ? true : false
      },

      hasGiftWrap() {
        return this.enableGiftWrap && this.giftWrapProduct
      },
    },

    watch: {
      cartItems(newVal) {
        this.potentialSmilePoints =
          this.$smile.calculatePointsFromCartItems(newVal)
      },

      giftWrap(newVal) {
        const variant = this.giftWrapProduct?.variants[0]

        if (!variant) return null

        const product = {
          isGiftWrap: true,
          id: this.giftWrapProduct.id,
          title: this.giftWrapProduct.title,
          brand: 'Gee Beauty',
          price: variant.price,
          variantTitle: variant.title,
          quantity: 1,
          variantId: `gid://shopify/ProductVariant/${variant.id}`,
        }

        if (newVal == true && this.hasGiftWrap) {
          this.addItemToCart(product)
        } else {
          this.removeItemFromCart(product)
        }
      },
    },

    methods: {
      ...mapActions([
        'addItemToCart',
        'removeItemFromCart',
        'openHeaderMenuExpand',
      ]),

      async goToCheckout() {
        try {
          this.isLoading = true

          if (this.cartNote.length) {
            await this.addNoteToCheckout({ note: this.cartNote })
          }

          if (this.enableTieredDiscount) {
            await this.applyTieredDiscount()
          }

          const checkout = await this.updateCheckout()
          const cartUrl = this.replaceCheckoutUrl(checkout.webUrl)

          await this.handleTracking(checkout)

          if (!this.user) {
            this.isLoading = false
            window.location = cartUrl
            return
          }

          this.isLoading = false
          const cartLoginUrl = await this.multipassLogin(cartUrl)

          window.location = cartLoginUrl
        } catch (error) {
          this.isLoading = false
          alert('Sorry, something went wrong on our end.')
          console.log(error)
        }
      },

      async applyTieredDiscount() {
        if (parseFloat(this.cartTotal) < 350) return null

        const cartTotalNum = await this.getDiscountedCartTotal()
        if (cartTotalNum >= 350 && cartTotalNum < 700) {
          await this.$shopify.applyDiscount('shopandsave15', this.checkoutId)
        }

        if (cartTotalNum >= 700) {
          await this.$shopify.applyDiscount('shopandsave20', this.checkoutId)
        }
      },

      async updateCheckout() {
        return new Promise(async (resolve, reject) => {
          try {
            const checkout = this.cartHasSubscription
              ? await this.handleShopifyCheckout()
              : await this.handleShopifyCheckout()

            console.log(checkout)
            resolve(checkout)
          } catch (error) {
            console.error(error)

            reject(error)
          }
        })
      },

      async handleShopifyCheckout() {
        const { cart } = this.$store.state

        // Create an array of the cart's line items,
        const lineItems = []

        cart.items.forEach(item => {
          const { isBundle, variantId, quantity } = item

          // Object holding the line item's Variant ID and its Cart Quantity
          if (!isBundle) {
            lineItems.push({ variantId, quantity })
          } else {
            item.products.forEach(bundleItem => {
            	lineItems.unshift({ variantId: bundleItem.variantId, quantity });
            });

            lineItems.unshift({ variantId: item.bundleVariantId, quantity });
          }
        })

        lineItems.flat()

        // Update the Shopify Checkout with the Line Items
        return await this.$store.dispatch('updateCheckout', lineItems)
      },

      async handleRechargeCheckout() {
        const { cart } = this.$store.state

        const payload = {
          line_items: this.$recharge.prepareLineItems(cart.items),
        }

        if (this.cartNote) {
          payload.note = this.cartNote
        }

        return await this.$recharge.createCheckout(payload)
      },

      getSubscriptionInterval(frequency) {
        const subInfo = {
          'once-a-week': 1,
          'every-two-weeks': 2,
          'once-a-month': 4,
          default: null,
        }

        return subInfo[frequency] || subInfo['default']
      },

      async addNoteToCheckout(payload) {
        const { data } = await this.$shopify.client.mutate({
          mutation: updateCheckoutAttributes,
          variables: {
            checkoutId: this.checkoutId,
            input: payload,
          },
        })
      },

      async multipassLogin(cartUrl) {
        const tld = this.locale === 'en-ca' ? 'ca' : 'com'
        const { data } = await axios.post(
          `https://geebeauty.${tld}/api/multipass`,
          {
            email: this.user.email,
            created_at: this.user.created_at,
            return_to: cartUrl,
          },
          { headers: { locale: this.locale } }
        )

        return data.loginUrl
      },

      replaceCheckoutUrl(webUrl) {
        const storeLocale =
          this.locale === 'en-ca'
            ? 'gee-beauty-toronto'
            : 'gee-beauty-bal-harbour'
        const tld = this.locale === 'en-ca' ? 'ca' : 'com'

        return webUrl.replace(
          `${storeLocale}.myshopify.com`,
          `checkout.geebeauty.${tld}`
        )
      },

      handleTracking(checkout) {
        try {
          this.$gtag.event('begin_checkout', {
            items: this.formatGtagLineItems(this.cartItems),
          })

          this.$fb.initiateCheckout({
            lineItems: checkout.lineItems.edges,
            value: `${checkout.totalPrice}`,
            currency: this.$store.state.locale === 'en-ca' ? 'CAD' : 'USD',
          })

          // this.$pinterest.checkout({
          //   value: `${checkout.totalPrice}`,
          //   order_quantity: this.numCartItems,
          //   currency: this.$store.state.locale === 'en-ca' ? 'CAD' : 'USD',
          //   lineItems: checkout.lineItems.edges
          // })
        } catch (error) {
          console.log(error)
        }
      },

      formatGtagLineItems(lineItems) {
        return lineItems.map(product => ({
          id: product.id,
          name: product.title,
          brand:
            product.brand && product.brand.title
              ? getTitle(product.brand.title)
              : product.brand,
          category:
            product.product_type && getTitle(product.product_type.title),
          variant: product.variantTitle,
          quantity: product.quantity,
          price: product.price,
        }))
      },

      async getDiscountedCartTotal() {
        if (this.nonGiftWrapItems.length === 0) return 0.0

        const discountIds = await this.$shopify.getDiscountProductIds()

        const items = this.nonGiftWrapItems

        const discountedItems = items.filter(item =>
          discountIds.includes(`gid://shopify/Product/${item.id}`)
        )

        const total = discountedItems.reduce((acc, lineItem) => {
          if (lineItem.isReward) return acc + 0

          const lineItemSum =
            parseFloat(
              lineItem.salePrice ? lineItem.salePrice : lineItem.price
            ).toFixed(2) * lineItem.quantity

          return acc + lineItemSum
        }, 0)

        return total.toFixed(2)
      },
    },

    async mounted() {
      const cartHasGiftWrap = this.cartItems.find(item => item?.isGiftWrap)

      this.giftWrap = !!cartHasGiftWrap
    },
  }
</script>
<style scoped>
  .loading {
    cursor: progress;
    pointer-events: none;
    position: relative;
  }

  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
    position: absolute;
    left: 90px;
    top: 39%;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
</style>
