<template>
  <div v-if="valueProps && valueProps.length > 0" class="header__cart__value-props">
    <ul>
      <li v-for="prop in valueProps" class="h6"><SvgIcon name="small-checkmark" /> {{ prop.prop }}</li>
    </ul>
  </div>
</template>

<script>
  import SvgIcon from "@/components/global/SvgIcon";

  export default {
    name: 'CartValueProps',

    components: {
      SvgIcon
    },

    props: {
      valueProps: {
        type: Array,
        default: []
      }
    }
  }
</script>
